<template>
  <div class="pay-result">
    <div class="logo">
      <img src="../../../assets/images/img/artemisads-logo.svg" />
    </div>
    <div class="content-wrap">
      <div class="content">
        <div class="submitted" v-if="status === 'submitted'">
          <span class="icon">
            <LoadingOutlined :style="{fontSize: '24px'}"/>
          </span>
          <p>Payment is being submitted…</p>
        </div>
        <div class="success" v-if="status === 'success'">
          <span class="duigou">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-duigou-jiacu"></use>
            </svg>
          </span>
          <p class="payment">Payment submitted successfully.</p>
          <p class="you-may">You may close this page.</p>
          <artmisads-button @click="() => toHome()">Back to ArtemisAds</artmisads-button>
        </div>
        <div class="progress" v-if="status === 'progress'">
          <div class="icon-wrap">
            <span class="duigou">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-duigou-jiacu"></use>
              </svg>
            </span>
            <span class="line"></span>
            <span class="loading">
              <LoadingOutlined :style="{fontSize: '24px'}"/>
            </span>
          </div>
          <div class="text-wrap">
            <p class="payment">Payment submitted successfully.</p>
            <span>Please wait for PayPal to confirm the result.</span>
            <p class="bill">Bill payment in progress…</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { useRouter } from "vue-router";
  import { LoadingOutlined } from '@ant-design/icons-vue';
  import { ref, onBeforeMount } from 'vue';
  import { financeApi } from "~/server";

  const router = useRouter();
  const spinning = ref(false);
  const paypalOrderId = ref('');
  const status = ref('submitted');
  // const status = ref('success');
  // const status = ref('progress');

  const financeSellerPaymentPaypalCapture = async () => {
    const paypalOrderIdValue = localStorage.getItem('SELLE_PAYPAL_ORDER_ID');
    if (paypalOrderIdValue) {
      paypalOrderId.value = paypalOrderIdValue;
      spinning.value = true;
      const params = {paypalOrderId: paypalOrderIdValue};
      const res = await financeApi.financeSellerPaymentPaypalCapture(params);
      const { data, success } = res;
      if (success) {
        if (data.paypalCaptureStatus === 'PENDING') {
          status.value = 'progress';
          pollingStatus(data.paypalCaptureId);
        } else if (data.paypalCaptureStatus === 'COMPLETED') {
          status.value = 'success';
        }
      }
      spinning.value = false;
    }
   
  };

  const pollingStatus = async (paypalCaptureId) => {
    const resFirst = await financeApi.financeSellerPaymentPaypalOrdersCaptures({paypalCaptureId});
    if (resFirst.success && resFirst.data.paypalCaptureStatus === 'COMPLETED') {
      status.value = 'success';
    } else {
      let times = 6;
      const timer = setInterval(async () => {
        const res = await financeApi.financeSellerPaymentPaypalOrdersCaptures({paypalCaptureId});
        const { success, data } = res;
        if (success && data.paypalCaptureStatus === 'COMPLETED') {
          status.value = 'success';
          clearInterval(timer)
        } else if (times === 0) {
          clearInterval(timer);
        }
        times -= 1;
      }, 5000)
    }
  }

  onBeforeMount(() => {
    financeSellerPaymentPaypalCapture();
  })

  const toHome = () => {
    if (window.opener) {
      window.opener.location.reload();
    }
    const { href } = router.resolve({ path: `/seller/finance/payments` });
    window.open(href, '_self');
  }

</script>
<style lang="less" scoped>
  .pay-result {
    width: 100%;
    .logo {
      margin-top: 24px;
      margin-left: 28px;
      img {
        width: 176px;
        height: 40px;
      }
    }
    .content-wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 98px;
    }
    .content {
      width: 720px;
      height: 476px;
      background: var(--color-white);
      box-shadow: var(-section-box-shadow-5);
      border-radius: 8px;
    }
    .success {
      display: flex;
      flex-direction: column;
      padding-top: 140px;
      .duigou {
        display: flex;
        background-color: var(--green-3);
        width: 64px;
        height: 64px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        border: 6px solid var(--green-9);
        align-self: center;
        svg {
          width: 32px;
          height: 32px;
          align-self: center;
          fill: var(--green-7);
        }
      }
      .payment {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        text-align: center;
        line-height: 30px;
        margin-top: 15px;
      }
      .you-may {
        margin-top: 7px;
        font-weight: 500;
        font-size: 16px;
        color: var(--dark-2);
        letter-spacing: 0.4px;
        text-align: center;
        line-height: 24px;
      }
      > button {
        width: 217px;
        align-self: center;
        margin-top: 16px;
      }
    }
    .progress {
      display: flex;
      padding-top: 128px;
      padding-left: 136px;
      .icon-wrap {
        display: flex;
        flex-direction: column;
        width: 56px;
        .line {
          height: 101px;
          width: 4px;
          background-color: var(--bg-gray-10);
          align-self: center;
        }
        .duigou {
          display: flex;
          background-color: var(--green-9);
          svg {
            width: 32px;
            height: 32px;
            align-self: center;
            fill: var(--green-3);
          }
        }
        .loading {
          background-color: var(--bg-gray-10);
          :deep(svg) {
            fill: var(--primary-text);
          }
        }
        .loading,
        .duigou {
          width: 56px;
          height: 56px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .text-wrap {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        > span {
          font-weight: 500;
          font-size: 16px;
          color: var(--dark-3);
          letter-spacing: 0.4px;
          line-height: 24px;
          margin-top: 7px;
        }
        > p {
          font-weight: 600;
          font-size: 20px;
          color: var(--dark-1);
          letter-spacing: 0.5px;
          line-height: 30px;
        }
        .payment {
          margin-top: 5px;
        }
        .bill {
          margin-top: 108px;
        }
      }
    }
    .submitted {
      width: 100%;
      padding-top: 124px;
      padding-left: 136px;
      display: flex;
      .icon {
        width: 56px;
        height: 56px;
        background-color: var(--bg-gray-10);
        
        border-radius: 100%;
        display: flex;
        justify-content: center;
        :deep(svg) {
          fill: var(--primary-text);
        }
      }
      > p {
        margin-left: 20px;
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 30px;
        align-self: center;
      }
    }
  }
</style>
